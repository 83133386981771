import { v4 as uuidv4 } from 'uuid';

export const useCalculator = () => {

  const {refParams, getCurrentParams, defaultLeasingParams} = useLeasingParams();
  const api = useAPI();

  const inertia = 100;
  const lastRequestTime = useState('lastCalcRequestTime', () => null);
  const carsStorage = useState('carsStorage', () => []);

  const requestStack = useState('requestStack', () => ({}));
  const responseStack = useState('responseStack', () => ({}));

  const uniqueId = "copy_" + uuidv4();
  const globals = useGlobals();

  const getInstallment = async (cars) => {
    if(process.server) return;
    const shouldSend = lastRequestTime.value < Date.now() - inertia;

    if (typeof cars.data.pop === 'undefined') {
      cars.data = [cars.data];
    }

    cars.data.forEach(car => {
      let requestId = uuidv4();

      requestStack.value[requestId] = car
      responseStack.value[requestId] = car;
      lastRequestTime.value = Date.now();

      clearTimeout(document.appGlobals['requestStackTimer'] || null);
      document.appGlobals['requestStackTimer'] = setTimeout(() => {
        requestForInstallment();
      }, inertia)
    })

  }

  const getOneInstallment = async (car) => {
    return requestForInstallment(car)
  }

  const requestForInstallment = async(oneCar = null) => {
    const params = getCurrentParams();
    params.cars = [];

    if(!oneCar) {
      const request = toRaw(requestStack.value);

      requestStack.value = {}
      Object.keys(request).forEach(requestId => {
        const car = request[requestId];
        if (car.price) {
          params.cars.push({
            id: requestId,
            price: car.price,
            manufacturing_year: Number(car?.productionYear),
            state: car?.state !== "Nowy" ? 1 : 0
          });
        }
      })
    } else {
      params.cars.push({
        id: oneCar.group_id,
        price: oneCar.price,
        manufacturing_year: Number(oneCar.productionYear),
        state: oneCar.state === "Nowy" ? 0 : 1
      });
    }

    const result = await api.request(
      "/calculate/",
      params,
      'post'
    );

    const response = result?.data?.data?.cars;

    if(oneCar) {
      return response;
    }

    response.forEach(responseData => {
      if(responseStack.value[responseData.id]) {
        responseStack.value[responseData.id].installment = responseData.installment;
      }
    });
    responseStack.value = {}

  }

  return {
    getInstallment,
    getOneInstallment,
  }
}
